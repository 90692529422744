/* guide to breakpoints

    <------XS------|a|------SM------|b|------MD------|c|------LG------|d|------XL------|e|----XXL----->
    <----LT-SM-----|a|
    <----LT-MD----------------------|b|
    <----LT-LG---------------------------------------|c|
    <----LT-XL--------------------------------------------------------|d|
    <----LT-XXL------------------------------------------------------------------------|e|
                   |a|------GT-XS--------------------------------------------------------------------->
                                    |b|------GT-SM---------------------------------------------------->
                                                     |c|------GT-MD----------------------------------->
                                                                      |d|------GT-LG------------------>
                                                                                       |e|---GT-XL---->
*/
.font-body {
  font-family: "Avenir", Arial, sans-serif !important;
  font-size: 16px;
  line-height: 110%;
}

.consumer-rights-form-container:not(.default):not(.no-styles) .consumer-rights-state .consumer-rights-state-next_button {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  background-color: #d81e05;
  border-color: #d81e05;
}
.consumer-rights-form-container:not(.default):not(.no-styles) .consumer-rights-form-section .consumer-rights-form .consumer-rights-form-submit_button {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  background-color: #d81e05;
  border-color: #d81e05;
}
.consumer-rights-form-container:not(.default):not(.no-styles) .consumer-rights-form-section .validate-errors-list {
  color: #d81e05;
}
.consumer-rights-form-container:not(.default):not(.no-styles) .consumer-rights-form-message-section .error-message__text,
.consumer-rights-form-container:not(.default):not(.no-styles) .consumer-rights-privacy-section .error-message__text {
  color: #d81e05;
}

.consumer-rights-form-container.default .consumer-rights-state .consumer-rights-state-next_button {
  background-color: #d81e05;
  border-color: #d81e05;
}
.consumer-rights-form-container.default .consumer-rights-form-section .consumer-rights-form .consumer-rights-form-submit_button {
  background-color: #d81e05;
  border-color: #d81e05;
}
.consumer-rights-form-container.default .consumer-rights-form-section .validate-errors-list {
  color: #d81e05;
}
.consumer-rights-form-container.default .consumer-rights-form-message-section .error-message__text,
.consumer-rights-form-container.default .consumer-rights-privacy-section .error-message__text {
  color: #d81e05;
}

.cookie-consent-container:not(.default):not(.no-styles) {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
}
.cookie-consent-container:not(.default):not(.no-styles) .cookie-consent-wrapper .cc-submit__btn {
  font-family: "AvenirHeavy", Arial, sans-serif !important;
  background-color: #d81e05;
}
.cookie-consent-container:not(.default):not(.no-styles) .cookie-consent-wrapper .cc-submit__btn:hover {
  background-color: rgb(166.1538461538, 23.0769230769, 3.8461538462);
}